











































































import {
  Vue,
  Prop,
  Emit,
  Watch,
  Component,
} from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import AuthConnector from '@/connector/Auth.vue';

@Component({
  components: {
    BaseDataTable,
    AuthConnector,
  },
})
export default class TransactionPage extends Vue {
  public transactionIndex: number = 0;

  public transactions: any = {};

  // eslint-disable-next-line class-methods-use-this
  getTransactionStatusText(status: string): string {
    if (status === 'APPROVED') return 'สำเร็จ';
    if (status === 'REJECTED') return 'ไม่สำเร็จ';
    return 'กำลังดำเนินการ';
  }

  // eslint-disable-next-line class-methods-use-this
  getTransactionStatusColor(status: string): string {
    if (status === 'APPROVED') return 'green';
    if (status === 'REJECTED') return 'red';
    return 'amber';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get transactionEndpoint() {
    let transactionType;
    if (this.transactionIndex === 0) transactionType = 'deposit';
    if (this.transactionIndex === 1) transactionType = 'withdraw';
    if (this.transactionIndex === 2) transactionType = 'promotions';
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions/${transactionType}`;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get headers() {
    if (this.transactionIndex !== 2) {
      return [
        { text: 'เวลาที่แจ้ง', value: 'request_time' },
        { text: 'เวลาที่ทำรายการ', value: 'updated_at' },
        { text: 'จำนวนเงิน', value: 'amount' },
        { text: 'สถานะ', value: 'status' },
      ];
    }
    return [
      { text: 'โปรโมชั่น', value: 'promotion_name' },
      { text: 'จำนวนเงิน', value: 'amount' },
    ];
  }
}
