var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('v-container',{staticStyle:{"max-width":"1200px"}},[_c('v-row',{staticClass:"my-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('center',[_c('h1',[_vm._v(" ประวัติการทำรายการ ")])])],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.transactionIndex),callback:function ($$v) {_vm.transactionIndex=$$v},expression:"transactionIndex"}},[_c('v-tab',[_vm._v(" ฝาก ")]),_c('v-tab',[_vm._v(" ถอน ")]),_c('v-tab',[_vm._v(" โบนัส ")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('auth-connector',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('base-data-table',{ref:"USER_TABLE",attrs:{"data-url":_vm.transactionEndpoint,"request-header":{
                'authorization': ("Bearer " + token)
              },"filters":{
                paginate: true,
              },"headers":_vm.headers},scopedSlots:_vm._u([{key:"request_time",fn:function(ref){
              var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(props.request_time))+" ")]}},{key:"updated_at",fn:function(ref){
              var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(props.updated_at))+" ")]}},{key:"status",fn:function(ref){
              var props = ref.props;
return [_c('span',{class:((_vm.getTransactionStatusColor(props.status)) + "--text")},[_vm._v(" "+_vm._s(_vm.getTransactionStatusText(props.status))+" ")])]}},{key:"promotion_name",fn:function(ref){
              var props = ref.props;
return [_vm._v(" "+_vm._s(props.promotion.promotion_name)+" ")]}}],null,true)})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }